import isEqual from "react-fast-compare"

export { isEqual }
export const areObjectsEqual = (
	obj1: object | undefined,
	obj2: object | undefined,
): boolean => {
	return isEqual(obj1, obj2)
}

export const detectDifferentItem = (
	obj1: object | undefined,
	obj2: object | undefined,
) => {
	const array = Object.keys(obj1).map((key) => {
		const v1 = obj1[key]
		const v2 = obj2[key]
		// const v1Str = v1 === undefined ? "undefined" : JSON.stringify(v1)
		// const v2Str = v2 === undefined ? "undefined" : JSON.stringify(v2)
		return {
			key,
			isDifferent: !areObjectsEqual(v1, v2),
			diff: `${v1} !== ${v2}`,
			v1,
			v2,
		}
	})
	const filtered = array
		.filter((item) => item.isDifferent)
		.map((item) => ({
			key: item.key,
			diff: item.diff,
			v1: item.v1,
			v2: item,
		}))
	console.log("all", filtered)
	return filtered
}
