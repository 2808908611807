import { DIRTY_WORDS } from "@constants/nGWords"
import { ENV_CONFIG, isNotProd } from "../../env_config"
export const SERVICE_NAME = "ClassCloud"
const SCHEME_MAP = {
	prod: "class-cloud",
	stg: "stg-class-cloud",
	local: "local-class-cloud",
}
export const APP_SCHEMA = SCHEME_MAP[ENV_CONFIG.ENVIRONMENT]
export const ANDROID_PACKAGE_NAME = "jp.class_cloud.classcloud"
export const IOS_APP_STORE_URL =
	"https://apps.apple.com/jp/app/classcloud/id1604349518"
export const HEADER_HEIGHT = 48
export const TAB_HEIGHT = 42
export const FOOTER_HEIGHT = 72
// export const FOOTER_WIDTH = 64
export const FOOTER_WIDTH = 160
export const PASSWORD_MIN_LENGTH = 8
export const INPUT_LENGTH = { long: 300, short: 100 }
export const PASSWORD_MIN_LENGTH_FURIGANA = "はち"
export { ENV_CONFIG as CONFIG, isNotProd }
export const isProd = !isNotProd

export const MAX_FILE_SIZE = 200 * 1000 * 1000
export const IMAGE_COMPRESS_THRESHOLD_FILE_SIZE = 5 * 1000 * 1000

export const LOGOUT_CODE = "96966910"
export const ANALYTICS_OPT_OUT_CONTROL_CODE = "96966910"
export const EMOJI_LIST = [
	{ emojiUnicode: "1f44d", emojiName: "thumbs_up_sign", text: "👍" },
	{ emojiUnicode: "2764-fe0f", emojiName: "heart", text: "❤️" },
	{ emojiUnicode: "1f604", emojiName: "smile", text: "😄" },
	{ emojiUnicode: "1f602", emojiName: "joy", text: "😂" },
	{ emojiUnicode: "1f644", emojiName: "face_with_rolling_eyes", text: "🙄" },
	{ emojiUnicode: "1f914", emojiName: "thinking_face", text: "🤔" },
	// { emojiUnicode: "1f92e", emojiName: "face_vomiting", text: "🤮" },
	{ emojiUnicode: "1f44f", emojiName: "clapping_hands", text: "👏" },
	{ emojiUnicode: "1f389", emojiName: "tada", text: "🎉" },
	{ emojiUnicode: "1f680", emojiName: "rocket", text: "🚀" },
	{ emojiUnicode: "1f440", emojiName: "eyes", text: "👀" },
	{ emojiUnicode: "1f631", emojiName: "scream", text: "😱" },
	{
		emojiUnicode: "1f647-200d-2642-fe0f",
		emojiName: "man-bowing",
		text: "🙇‍♂️",
	},
	{ emojiUnicode: "1f44c", emojiName: "ok_hand", text: "👌" },
	{
		emojiUnicode: "1f646-200d-2642-fe0f",
		emojiName: "man-gesturing-ok",
		text: "🙆‍♂️",
	},
	{ emojiUnicode: "1f525", emojiName: "fire", text: "🔥" },
	{ emojiUnicode: "1f64f", emojiName: "pray", text: "🙏" },
	{ emojiUnicode: "1f4aa", emojiName: "flexed_biceps", text: "💪" },
	{ emojiUnicode: "1f4af", emojiName: "100", text: "💯" },
	{ emojiUnicode: "1f197", emojiName: "ok", text: "🆗" },
	// { emojiUnicode: "1f196", emojiName: "ng", text: "🆖" },
	{ emojiUnicode: "0030-fe0f-20e3", emojiName: "zero", text: "0️⃣" },
	{ emojiUnicode: "0031-fe0f-20e3", emojiName: "one", text: "1️⃣" },
	{ emojiUnicode: "0032-fe0f-20e3", emojiName: "two", text: "2️⃣" },
	{ emojiUnicode: "0033-fe0f-20e3", emojiName: "three", text: "3️⃣" },
	{ emojiUnicode: "0034-fe0f-20e3", emojiName: "four", text: "4️⃣" },
	{ emojiUnicode: "0035-fe0f-20e3", emojiName: "five", text: "5️⃣" },
	{ emojiUnicode: "0036-fe0f-20e3", emojiName: "six", text: "6️⃣" },
	{ emojiUnicode: "0037-fe0f-20e3", emojiName: "seven", text: "7️⃣" },
	{ emojiUnicode: "0038-fe0f-20e3", emojiName: "eight", text: "8️⃣" },
	{ emojiUnicode: "0039-fe0f-20e3", emojiName: "nine", text: "9️⃣" },
]

export const DEFAULT_COMMENT_FORM_HEIGHT = 120

export const ALL_COURSES = "all courses"
export const ALL_COURSES_IN_JP = "すべての授業"
export const ALL_COURSES_FURIGANA = "すべてのじゅぎょう"
export const ALL_TERMS = "all terms"
export const ALL_TERMS_IN_JP = "全学期"
export const ALL_TERMS_FURIGANA = "ぜんがっき"

export const COMMENT = {
	text: "コメント",
	furigana: "こめんと",
}

export const POST = {
	text: "投稿",
	furigana: "とうこう",
}

export const THREAD = {
	text: "スレッド",
	furigana: "すれっど",
}

export const MENTION = {
	text: "メンション",
	furigana: "めんしょん",
}

export const REACTION = {
	text: "リアクション",
	furigana: "りあくしょん",
}

export const CLASS = {
	text: "クラス",
	furigana: "くらす",
}

export const COURSE = {
	text: "授業",
	furigana: "じゅぎょう",
}

export const FIXATION = {
	text: "固定",
	furigana: "こてい",
}

export const NG_WORDS = DIRTY_WORDS

export const HELP_PAGE_HOST = "https://help.class-cloud.jp"

export const COLLAB_BOARD_SCHEMA_VERSION = "1"

export const WATANABE_CLASSROOM_ID = "f50670a8-c393-42cb-be1b-c2b78ee9359d"
export const WATANABE_USER_ID = "ba1f220e-53c9-41fe-b52d-9ab147819103"
