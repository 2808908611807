import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NewArrivalStatesForBottomTabsType } from "@type/models/NewArrival"

export type TabKey = keyof NewArrivalStatesForBottomTabsType
const initialState = {
	home: { exists: false },
	notification: { count: 0 },
	school: {
		count: 0,
		unreadAnnouncementsCounts: 0,
		pendingPatronRequestsCount: 0,
	},
	mypage: { count: 0 },
	supportChat: { count: 0 },
	consultationChat: { count: 0 },
} as NewArrivalStatesForBottomTabsType

const slice = createSlice({
	name: "newArrivalStatesForBottomTabs",
	initialState,
	reducers: {
		setNewArrivalStatesForBottomTabs: <T extends TabKey>(
			state: NewArrivalStatesForBottomTabsType,
			action: PayloadAction<Partial<NewArrivalStatesForBottomTabsType>>,
		) => {
			return { ...state, ...action.payload }
		},
	},
})

export const newArrivalStatesForBottomTabsReducer = slice.reducer
export const { setNewArrivalStatesForBottomTabs } = slice.actions
