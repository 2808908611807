import { localAnnouncementViewReducer } from "@features/announcementView/localAnnouncementView"
import { AnyAction, Reducer, configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { commentFormContentReducer } from "./commentFormContent"
import { courseReducer } from "./course"
import { currentScreenReducer } from "./currentScreen"
import { localPostViewReducer } from "./localPostView"
import { mediaListForComparisonReducer } from "./mediaListForComparison"
import { notificationReducer } from "./notification"
import { postContextReducer } from "./postContext"
// import { postDetailContextReducer } from "./postDetailContext__"
import { globalVideoStopReducer } from "@stores/globalVideoStop"
import { newArrivalStatesForBottomTabsReducer } from "@stores/newArrivalStatesForBottomTabs"
import { queryStringReducer } from "@stores/queryString"
import { resetReducer } from "./reset"
import { authReducer } from "./userInfo"

const reducers = combineReducers({
	userInfo: authReducer,
	currentScreen: currentScreenReducer,
	course: courseReducer,
	postContext: postContextReducer,
	// postDetailContext: postDetailContextReducer,
	mediaListForComparison: mediaListForComparisonReducer,
	commentFormContent: commentFormContentReducer,
	localPostView: localPostViewReducer,
	localAnnouncementView: localAnnouncementViewReducer,
	notification: notificationReducer,
	newArrivalStatesForBottomTabs: newArrivalStatesForBottomTabsReducer,
	globalVideoStop: globalVideoStopReducer,
	queryString: queryStringReducer,
	reset: resetReducer,
})

const REDUX_PERSIST_KEY = "root7"

const persistConfig = {
	key: REDUX_PERSIST_KEY,
	storage,
	blacklist: [
		"postContext",
		// "postDetailContext",
		"mediaListForComparison",
		"localPostView",
		"localAnnouncementView",
		"newArrivalStatesForBottomTabs",
		"globalVideoStop",
		"queryString",
		// "commentFormContent",
	],
}
const persistedReducer = persistReducer(persistConfig, reducers)

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === "reset/clearAllStoreIncludingPersists") {
		localStorage.removeItem(`persist:${REDUX_PERSIST_KEY}`)
		state = {} as RootState
	}
	return persistedReducer(state, action)
}

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ thunk: false, serializableCheck: false }),
})
export { store }
export type ReducersType = typeof reducers
export type RootState = ReturnType<typeof persistedReducer>
