import { useAppVisibility } from "@hooks/useAppVisibility"
import { useShouldRefetch } from "@hooks/useShouldRefetch"
import { useUser } from "@hooks/useUser"
import { setNewArrivalStatesForBottomTabs } from "@stores/newArrivalStatesForBottomTabs"
import { NewArrivalState } from "@type/models/NewArrival"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import useSWR from "swr"

export const KEY = "/api/v1/users/me/new_arrival_exists_state"

export const useLoadNewArrivalStatesForBottomTabs = (): void => {
	const { data, mutate } = useSWR<NewArrivalState>(KEY, {
		dedupingInterval: 60 * 5,
	})
	const { isVisible } = useAppVisibility()
	const user = useUser()

	const dispatch = useDispatch()

	const { getShouldFetch, setLastFetchedAt } = useShouldRefetch({
		intervalMs: 1000 * 60 * 3,
	})

	useEffect(() => {
		if (!user || !isVisible) return
		if (getShouldFetch()) {
			mutate()
			setLastFetchedAt()
		}
	}, [user, isVisible])

	useEffect(() => {
		data && updateTab(data)
	}, [data])

	const updateTab = (data: NewArrivalState) => {
		dispatch(
			setNewArrivalStatesForBottomTabs({
				home: data.posts,
				notification: data.notifications,
				school: data.school,
				mypage: data.mypage,
				supportChat: data.supportChat,
				consultationChat: data.consultationChat,
			}),
		)
	}
}
