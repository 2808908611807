import { isTouchScreen } from "@helpers/detectDevice"
import {
	FirebaseConfig,
	LOCAL_FIREBASE_CONFIG,
	PROD_FIREBASE_CONFIG,
	STG_FIREBASE_CONFIG,
} from "./env_firebase_config"

type EnvironmentType = "prod" | "stg" | "local"
export const ENVIRONMENT = process.env
	.NEXT_PUBLIC_ENVIRONMENT as EnvironmentType

type Config = {
	API_HOST: string
	ASYNC_API_HOST: string
	EDGE_API_WS_HOST: string
	COLLAB_WS_HOST: string
	WEB_APP_HOST: string
	WS_HOST: string
	LP_HOST: string
	PDF_TO_IMAGE_HOST: string
	STATIC_FILE_HOST: string
	AMPLITUDE_SDK_ID: string | undefined
	FIREBASE_CONFIG: FirebaseConfig
	ENVIRONMENT: EnvironmentType
	MICROCMS_API_KEY: string
}

const PROD_CONFIG: Config = {
	API_HOST: "https://api.class-cloud.jp",
	ASYNC_API_HOST: "https://async-api.class-cloud.jp",
	EDGE_API_WS_HOST: "wss://edge-api.class-cloud.jp",
	COLLAB_WS_HOST: "wss:test-collab.class-cloud.jp",
	WEB_APP_HOST: "https://app.class-cloud.jp",
	WS_HOST: "wss://ws.class-cloud.jp",
	LP_HOST: "https://class-cloud.jp",
	PDF_TO_IMAGE_HOST: "https://pdf-to-image-api.class-cloud.jp/prod",
	STATIC_FILE_HOST: "https://static.class-cloud.jp/static",
	AMPLITUDE_SDK_ID: "596047b58d7ebdddd2e3b48673f96716",
	FIREBASE_CONFIG: PROD_FIREBASE_CONFIG,
	MICROCMS_API_KEY: "enStLz9Sbq1NoF9WVu1HF6PvlcUctfKRVjfC",
	ENVIRONMENT: "prod",
}

const STG_CONFIG: Config = {
	API_HOST: "https://stg-m-api.class-cloud.jp",
	ASYNC_API_HOST: "https://stg-m-async-api.class-cloud.jp",
	EDGE_API_WS_HOST: "wss://stg-m-edge-api.class-cloud.jp",
	COLLAB_WS_HOST: "wss:test-collab.class-cloud.jp",
	WEB_APP_HOST: "https://stg-m-app.class-cloud.jp",
	WS_HOST: "wss://stg-m-ws.class-cloud.jp",
	LP_HOST: "https://stg.class-cloud.jp",
	PDF_TO_IMAGE_HOST: "https://stg-m-pdf-to-image-api.class-cloud.jp/stg",
	STATIC_FILE_HOST: "https://stg-static.class-cloud.jp/static",
	AMPLITUDE_SDK_ID: undefined,
	FIREBASE_CONFIG: STG_FIREBASE_CONFIG,
	MICROCMS_API_KEY: "enStLz9Sbq1NoF9WVu1HF6PvlcUctfKRVjfC",
	ENVIRONMENT: "stg",
}

const LOCAL_IP = isTouchScreen() ? "172.22.1.141" : "127.0.0.1"
// const LOCAL_IP = isTouchScreen() ? "172.22.1.125" : "172.22.1.125"

const LOCAL_CONFIG: Config = {
	// API_HOST: "https://stg-m-api.class-cloud.jp",
	// API_HOST: "http://127.0.0.1:8099",
	API_HOST: `http://${LOCAL_IP}:8100`,
	// ASYNC_API_HOST: "http://127.0.0.1:8096",
	ASYNC_API_HOST: `http://${LOCAL_IP}:8096`,
	// API_HOST: isTouchScreen()
	// 	? `http://${LOCAL_IP}:8099`
	// 	: "http://127.0.0.1:8099",
	EDGE_API_WS_HOST: "wss://stg-m-edge-api.class-cloud.jp",
	// API_HOST: "http://192.168.2.102:8089",
	// API_HOST: "http://192.168.2.113:8099",
	// WS_HOST: "ws://127.0.0.1:8097",
	COLLAB_WS_HOST: "wss:test-collab.class-cloud.jp",
	// COLLAB_WS_HOST: "wss:test-collab.class-cloud.jp",
	// 直Django
	// WS_HOST: `ws://${LOCAL_IP}:8098`,

	WS_HOST: `ws://${LOCAL_IP}:8097`,
	WEB_APP_HOST: `http://${LOCAL_IP}:3010`,
	STATIC_FILE_HOST: "http://127.0.0.1:9000/class-cloud/static",
	LP_HOST: "https://stg.class-cloud.jp",
	// PDF_TO_IMAGE_HOST: "http://127.0.0.1:9011",
	PDF_TO_IMAGE_HOST:
		// "http://127.0.0.1:9011",
		"https://stg-m-pdf-to-image-api.class-cloud.jp/stg",
	// "https://nojz2aiq98.execute-api.ap-northeast-1.amazonaws.com/prod",
	// "https://cqaexrwpg2.execute-api.ap-northeast-1.amazonaws.com/stg",
	// "http://127.0.0.1:9011",
	// PDF_TO_IMAGE_HOST: "https://pdf-to-image-api.class-cloud.jp/prod",
	AMPLITUDE_SDK_ID: undefined,
	FIREBASE_CONFIG: LOCAL_FIREBASE_CONFIG,
	MICROCMS_API_KEY: "enStLz9Sbq1NoF9WVu1HF6PvlcUctfKRVjfC",
	ENVIRONMENT: "local",
}

export const ENV_CONFIG: Config =
	ENVIRONMENT === "prod"
		? PROD_CONFIG
		: ENVIRONMENT === "stg"
		? STG_CONFIG
		: LOCAL_CONFIG

export const isNotProd =
	ENV_CONFIG.ENVIRONMENT === "stg" || ENV_CONFIG.ENVIRONMENT === "local"
